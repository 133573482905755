

  .section {
    margin: 0 auto;
  }
  .menu{
    margin-top: 20px;
  }
  @media screen and (min-width: 992px) {
    .section {
      width: 95vw;
    }
  }
  .title {
    text-align: center;
    margin-bottom: 3.2rem;
  }
  
  .btn-container {
    margin-bottom: 6.4rem;
    display: flex;
    justify-content: center;
    width: 100vw;
    flex-wrap: wrap;
  }
  
  .filter-btn {
    
    background: transparent;
    border-color: transparent;
    font-size: 1.6rem;
    text-transform: capitalize;
    margin: 0 0.8rem;
    letter-spacing: 1px;
    padding: 0.6rem 1.2rem;
    color:  rgb(0, 0, 0);
    cursor: pointer;
    transition: all 0.3s linear;
    border-radius: 0.4rem;
  }
  
  .filter-btn.active {
    border-bottom: 2px solid;
  }
  
  .section-center {
    width: 90vw;
    margin: 0 auto;
    max-width: 1170px;
    display: grid;
    gap: 4.8rem 3.2rem;
    justify-items: center;
  }
  
  .menu-item {
    display: grid;
    gap: 1.6rem 3.2rem;
    max-width: 40rem;
    font-size: 1.6rem;
  }
  
  .photo {
    object-fit: cover;
    height: 200px;
    width: 100%;
    border-radius: 0.4rem;
    display: block;
  }
  
  .logo {
    object-fit: cover;
    height: auto;
    width: 200px;
    border-radius: 0.4rem;  
  }
  
  .item-info header {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px dotted hsl(0, 0%, 0%);
  }
  .item-info h4 {
    margin-bottom: 0.8rem;
  }
  .price {
    color: #453114;
    margin-left: 10px;
    min-width: 6ch;
  }
  .item-text {
    margin-bottom: 0;
    padding-top: 1.6rem;
    font-size: 1.2rem;
  }
  
  
  @media screen and (min-width: 768px) {
    .menu-item {
      grid-template-columns: 225px 1fr;
      gap: 0 2rem;
      max-width: 64rem;
    }
    .photo {
      height: 175px;
    }
  }
  @media screen and (min-width: 1200px) {
    .section-center {
      width: 95vw;
      grid-template-columns: 1fr 1fr;
    }
    .photo {
      height: 150px;
    }
  }