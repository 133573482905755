html{
  font-size: 62.5%;
}
body{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: hsl(210, 36%, 96%);
  color: hsl(209, 61%, 16%);
  font-family: 'Montserrat', sans-serif; 
  font-weight: 400;
  font-style: normal;
}

*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  
}

