.hero{
    height: calc(100vh - 80px);
    width: 100vw;
    object-fit: cover;
    margin: 0;
    padding: 0;
    overflow:visible;
}
body::-webkit-scrollbar {display:none;}
.title{
    min-width: 202px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: white;
    font-size: 3rem;
    margin: 0;
    padding: 0;
}
.title h1{
    margin-bottom: 20px;
}
.title a{
    text-decoration: none;
}
.home{
    height: calc(100vh - 83px);
}