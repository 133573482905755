:root{
    --primary: rgb(141, 38, 38);
}

.btn{
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    color: white;
}

.btn:hover{
    transition: all 0.3 ease-out;
    background: white;
    color: black;
    transition: 250ms;
}

.btn--primary{
    background-color: var(--primary);
}
.btn--outline{
    background-color: transparent;
    color:white;
    padding: 8px 20px;
    border-radius: 4px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out
}

.btn--medium{
    padding: 1rem 2rem;
    border-radius: 4px;
    font-size: 1.92rem;
    color: rgb(255, 255, 255);
}

.btn--large{
    padding: 12px 26px;
    border-radius: 4px;
    font-size: 2.92rem;
    color: rgb(255, 255, 255);
}