
  body{
    overflow:visible;
}
  
  .ordernow{
    position: absolute;
    min-width: 282px;
    left: 50%;
    top: 100px;
    transform: translate(-50%,0%);
  }
  .ordernow > *{
    display:flex;
    flex-direction: column;
    align-items: center;
  }
  .socialicon{
      width: 80px;
      margin: 5px;
      border-radius: 20px;
  }
  .social-buttons{
    margin-bottom: 20px;
  }
  .number > *:last-child{
    font-size: 4.0rem;
    overflow:hidden;
    color: rgb(141, 38, 38);
  }